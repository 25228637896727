import React, { useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";

import axios from "axios";
import { authMiddleWare } from "../util/auth";

import moment from "moment";
import "moment/locale/el";

import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import GetAppIcon from "@material-ui/icons/GetApp";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import FolderIcon from "@material-ui/icons/Folder";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import { Divider } from "@material-ui/core";
import { DropzoneArea } from "material-ui-dropzone";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    maxWidth: 936,
    margin: "auto",
    overflow: "hidden",
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(0, 0, 2),
    textAlign: "center",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    padding: theme.spacing(2),
  },
  InputText: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
    marginTop: theme.spacing(2),
  },
  DropzoneArea: {
    marginTop: theme.spacing(2),
  },
  uiProgess: {
    position: "fixed",
    zIndex: "1000",
    height: "31px",
    width: "31px",
    left: "calc(50% - 75px)",
    top: "calc(50% - 75px)",
  },
  listText: {
    flex: 1,
    overFlow: "hidden",
    textOverflow: "ellipsis",
  },
  wrap: {
    wordBreak: "break-all",
  },
  ListItemAvatar: {
    marginRight: -20,
  },
  ListItemSecondaryAction: {
    marginRight: -20,
  },
}));

export default function Files() {
  const [allDocs, setallDocs] = React.useState([]);
  const [simioseis, setsimioseis] = React.useState("");
  const [fileObjects, setfileObjects] = React.useState({});
  const [open, setopen] = React.useState(false);
  const [docType, setdocType] = React.useState("simvolaio");
  const [docOpen, setdocOpen] = React.useState(false);
  const [docKey, setdocKey] = React.useState("");
  const [openDelete, setopenDelete] = React.useState(false);
  const [uiLoading, setUiLoading] = React.useState(false);
  const classes = useStyles();
  let history = useHistory();

  const handleDocOpen = (open, key) => (event) => {
    setdocKey(key);
    setdocOpen(open);
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const handleDocClose = () => {
    setdocOpen(false);
  };

  const handleClickOpen = () => {
    setopen(true);
  };

  const handleClose = () => {
    setopen(false);
  };

  const handleDocTypeEdit = (docKey) => (event) => {
    let newAllDocs = [...allDocs];
    newAllDocs[docKey].docType = event.target.value;
    setallDocs(newAllDocs);
    console.log(allDocs[docKey], "allDocs[docKey]");
  };

  const handleEdit = (docKey) => (event) => {
    let newAllDocs = [...allDocs];
    newAllDocs[docKey].simioseis = event.target.value;
    setallDocs(newAllDocs);
  };

  const handleDocTypeChange = (event) => {
    setdocType(event.target.value);
  };

  const handleChange = (event) => {
    setsimioseis(event.target.value);
  };

  const submitDoc = (event) => {
    event.preventDefault();
    setUiLoading(true);
    authMiddleWare(history);
    const authToken = localStorage.getItem("AuthToken");
    let form_data = new FormData();
    form_data.append("file", fileObjects[0]);
    console.log("POST docType", docType);
    axios.defaults.headers.common = { Authorization: `${authToken}` };
    axios
      .post("/docs", form_data, {
        headers: {
          "content-type": "multipart/form-data",
          doctype: docType,
          simioseis: simioseis,
        },
      })
      .then(() => {
        setopen(false);
        setUiLoading(false);
        fetchDocuments();
      })
      .catch((error) => {
        if (error.response.status === 403) {
          history.push("/login");
        }
        console.log(error);
        setUiLoading(false);
      });
  };

  const editDoc = (docKey) => (event) => {
    authMiddleWare(history);
    event.preventDefault();
    const doc = allDocs[docKey];
    setUiLoading(true);

    let options = {
      url: `/docs/${doc.docId}`,
      method: "put",
      data: doc,
    };
    const authToken = localStorage.getItem("AuthToken");
    axios.defaults.headers.common = { Authorization: `${authToken}` };
    axios(options)
      .then(() => {
        setdocOpen(false);
        fetchDocuments();
        setUiLoading(false);
      })
      .catch((error) => {
        setopen(true);
        console.log(error);
      });
  };

  const deleteDoc = (docKey) => (event) => {
    console.log("trying to delete opened doc");
    authMiddleWare(history);
    event.preventDefault();
    const docId = allDocs[docKey].docId;
    const storagePath = allDocs[docKey].storagePath;
    setUiLoading(true);

    const authToken = localStorage.getItem("AuthToken");
    axios.defaults.headers.common = { Authorization: `${authToken}` };
    axios
      .delete(`docs/${docId}`, { data: { storagePath: storagePath } })
      .then(() => {
        let newAllDocs = [...allDocs];
        newAllDocs.splice(docKey, 1);
        setdocOpen(false);
        setopenDelete(false);
        setallDocs(newAllDocs);
        setUiLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeleteOpen = (docKey) => (event) => {
    console.log("openDelete", openDelete);
    setopenDelete(true);
    setdocKey(docKey);
    console.log("trying to open delete dialog");
  };

  const DocRender = () => {
    return (
      <Dialog
        open={docOpen}
        onClose={handleDocClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {allDocs[docKey].originalFileName}
          <ListItem disableGutters={true}>
            <ListItemText
              primary={
                <Typography noWrap={true} className={classes.listText}>
                  {moment(allDocs[docKey].createdAt).locale("el").format("LLL")}
                </Typography>
              }
              secondary="Ημερομηνία ανεβάσματος"
            />
          </ListItem>
          <Divider />
          <Tooltip title="Λήψη">
            <IconButton
              aria-label="download"
              onClick={() => openInNewTab(allDocs[docKey].fileUrl)}
            >
              <GetAppIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Διαγραφή">
            <IconButton aria-label="delete" onClick={handleDeleteOpen(docKey)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </DialogTitle>
        <DialogContent>
          <List dense={true}>
            <form className={classes.form} noValidate>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="doc-type">Είδος εγγράφου</InputLabel>
                <Select
                  labelId="doc-type-label"
                  id="doc-type"
                  value={allDocs[docKey].docType}
                  onChange={handleDocTypeEdit(docKey)}
                  label="Είδος εγγράφου"
                >
                  <MenuItem value="simvolaio">Συμβόλαιο</MenuItem>
                  <MenuItem value="logariasmos">Λογαριαμός</MenuItem>
                  <MenuItem value="PEA">ΠΕΑ</MenuItem>
                  <MenuItem value="other">Άλλο</MenuItem>
                </Select>
              </FormControl>
              {/* {allDocs[docKey].simioseis === "undefined"
                ? (allDocs[docKey].simioseis = "")
                : null} */}
              <TextField
                className={classes.InputText}
                multiline
                label="Σημειώσεις"
                variant="outlined"
                id="simioseis"
                value={
                  allDocs[docKey].simioseis === "undefined"
                    ? ""
                    : allDocs[docKey].simioseis
                }
                onChange={handleEdit(docKey)}
              />
            </form>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={editDoc(docKey)} color="primary">
            Αποθήκευση
          </Button>
          <Button onClick={() => setdocOpen(false)} color="primary">
            Κλείσιμο
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const generateDocList = (element) => {
    return [...Array(allDocs.length).keys()].map((value) => element(value));
  };

  const docTypeGreek = (docType) => {
    if (docType === "simvolaio") {
      return "Συμβόλαιο";
    } else if (docType === "logariasmos") {
      return "Λογαριαμός";
    } else if (docType === "PEA") {
      return "ΠΕΑ";
    } else if (docType === "other") {
      return "Άλλο";
    } else return null;
  };

  const listRender = (key) => {
    return (
      <List dense={true}>
        <ListItem
          key={key}
          disableGutters={true}
          button
          onClick={handleDocOpen(true, key)}
        >
          <ListItemAvatar className={classes.ListItemAvatar}>
            <DescriptionOutlinedIcon>
              <FolderIcon />
            </DescriptionOutlinedIcon>
          </ListItemAvatar>
          <ListItemText
            primary={
              <Typography noWrap={true} className={classes.listText}>
                {docTypeGreek(allDocs[key].docType)}
              </Typography>
            }
            secondary={
              <Typography
                // noWrap={true}
                // className={classes.listText}
                className={classes.wrap}
                color="textSecondary"
              >
                {allDocs[key].originalFileName}
              </Typography>
            }
          />
          <ListItemSecondaryAction className={classes.ListItemSecondaryAction}>
            <Tooltip title="Λήψη">
              <IconButton
                aria-label="download"
                onClick={() => openInNewTab(allDocs[key].fileUrl)}
              >
                <GetAppIcon />
              </IconButton>
            </Tooltip>
            {/* <Tooltip title="Delete">
                  <IconButton
                    aria-label="delete"
                    onClick={() => deleteDoc(key)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip> */}
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    );
  };

  const fetchDocuments = useCallback(() => {
    setUiLoading(true);
    authMiddleWare(history);
    const authToken = localStorage.getItem("AuthToken");
    axios.defaults.headers.common = { Authorization: `${authToken}` };
    axios
      .get("/docs")
      .then((response) => {
        setallDocs(response.data);
        setUiLoading(false);
      })
      .then(() => {
        console.log("get all docs response");
      })
      .catch((err) => {
        console.log(err);
      });
  }, [history]);

  useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments]);

  if (uiLoading === true) {
    return (
      <div className={classes.root}>
        {uiLoading && (
          <CircularProgress size={150} className={classes.uiProgess} />
        )}
      </div>
    );
  } else {
    return (
      <React.Fragment>
        <Card variant="outlined" className={classes.root}>
          <CardContent>
            <Typography variant="h6" className={classes.title}>
              Ανεβασμένα έγγραφα
            </Typography>
            {allDocs.length > 0 ? (
              generateDocList(listRender)
            ) : (
              <Typography align="center">
                Δεν έχετε ανεβάσει κανένα έγγραφο
              </Typography>
            )}
          </CardContent>

          <CardActions style={{ justifyContent: "center" }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleClickOpen}
            >
              Νέο έγγραφο
            </Button>
            <Dialog
              fullWidth={true}
              maxWidth={"sm"}
              open={open}
              onClose={handleClose}
              aria-labelledby="max-width-dialog-title"
            >
              <DialogTitle id="max-width-dialog-title">
                Είδος εγγράφου
              </DialogTitle>
              <DialogContent>
                <form className={classes.form} noValidate>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="doc-type">Είδος εγγράφου</InputLabel>
                    <Select
                      labelId="doc-type-label"
                      id="doc-type"
                      value={docType}
                      onChange={handleDocTypeChange}
                      label="Είδος εγγράφου"
                    >
                      <MenuItem value="simvolaio">Συμβόλαιο</MenuItem>
                      <MenuItem value="logariasmos">Λογαριαμός</MenuItem>
                      <MenuItem value="PEA">ΠΕΑ</MenuItem>
                      <MenuItem value="other">Άλλο</MenuItem>
                    </Select>
                  </FormControl>

                  <TextField
                    className={classes.InputText}
                    multiline
                    label="Σημειώσεις"
                    defaultValue="Πχ: Τελευταία αλλαγή 2012"
                    variant="outlined"
                    id="simioseis"
                    value={simioseis}
                    onChange={handleChange}
                  />
                  <div>
                    <DropzoneArea
                      dropzoneClass={classes.DropzoneArea}
                      dropzoneText={"Κάνε κλικ ή σύρε και άφησε ένα αρχείο"}
                      maxFileSize={1024 * 1024 * 10}
                      showFileNamesInPreview={true}
                      filesLimit={1}
                      onChange={(files) => {
                        setfileObjects(files);
                      }}
                    />
                  </div>
                </form>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Ακύρωση
                </Button>
                <Button onClick={submitDoc} color="primary">
                  Αποστολή
                </Button>
              </DialogActions>
            </Dialog>
            {/* <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleDocClickOpen}
                  >
                    Open form dialog
                  </Button> */}
          </CardActions>
        </Card>
        {docOpen && docKey !== null ? DocRender() : null}

        <Dialog
          open={openDelete}
          onClose={() => setopenDelete(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Είστε σίγουροι ότι θέλετε να διαγράψετε αυτό το έγγραφο;"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Εάν επιλέξεται ΝΑΙ, δεν υπάρχει δυνατότητα επαναφοράς.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={deleteDoc(docKey)} color="primary" autoFocus>
              Ναι
            </Button>
            <Button onClick={() => setopenDelete(false)} color="primary">
              Όχι
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}
