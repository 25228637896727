import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import axios from "axios";

import Store from "./state"; //Store

import login from "./pages/firebaseui/login";
import home from "./paperbase/Paperbase";

function App() {
  return (
    <Store>
      <Router>
        <div>
          <Switch>
            <Route exact path="/" component={home} />
            <Route exact path="/login" component={login} />
          </Switch>
        </div>
      </Router>
    </Store>
  );
}

axios.defaults.baseURL =
  "https://us-central1-i-done-71476.cloudfunctions.net/api";
//axios.defaults.baseURL = "http://localhost:5000/i-done-71476/us-central1/api";

export default App;
