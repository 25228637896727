import React, { useContext } from "react";
import clsx from "clsx";
import { Context } from "../state";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";

import axios from "axios";
import { authMiddleWare } from "../util/auth";

import { Container } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import {
  Card,
  CardActions,
  CardContent,
  Button,
  Grid,
  TextField,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Box from "@material-ui/core/Box";
import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    paddingBottom: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
  details: {
    display: "flex",
  },
  avatar: {
    height: 110,
    width: 100,
    flexShrink: 0,
    flexGrow: 0,
  },
  locationText: {
    paddingLeft: "15px",
  },
  buttonProperty: {
    position: "absolute",
    top: "50%",
  },
  uiProgess: {
    position: "fixed",
    zIndex: "1000",
    height: "31px",
    width: "31px",
    left: "calc(50% - 75px)",
    top: "calc(50% - 75px)",
  },
  progess: {
    position: "absolute",
  },
  uploadButton: {
    marginLeft: "8px",
    margin: theme.spacing(1),
  },
  customError: {
    color: "red",
    fontSize: "0.8rem",
    marginTop: 10,
  },
  submitButton: {
    marginTop: "10px",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Account(props) {
  const [state, dispatch] = useContext(Context);
  const [openDelete, setopenDelete] = React.useState(false);
  const [firstName, setfirstName] = React.useState(state.userdata.firstName);
  const [lastName, setlastName] = React.useState(state.userdata.lastName);
  const [email, setemail] = React.useState(state.userdata.email);
  const [phone, setphone] = React.useState(state.userdata.phone);
  const [buttonLoading, setbuttonLoading] = React.useState(false);
  const [imageError, setimageError] = React.useState("");
  const [image, setimage] = React.useState("");
  const [alertOpen, setalertOpen] = React.useState(false);
  //const [file, setfile] = React.useState({});
  //const [uid, setuid] = React.useState("");
  //const [name, setname] = React.useState("");
  //const [social_picture_url, setsocial_picture_url] = React.useState("");

  const classes = useStyles();
  const { ...rest } = props;
  let history = useHistory();

  const deleteUser = (event) => {
    authMiddleWare(history);
    dispatch({ type: "UI_LOADING", payload: true });

    const authToken = localStorage.getItem("AuthToken");
    axios.defaults.headers.common = { Authorization: `${authToken}` };
    axios
      .post(`deletedata`)
      .then(() => {
        localStorage.clear();
        history.push("/login");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleImageChange = (event) => {
    setimage(event.target.files[0]);
  };

  const profilePictureHandler = (event) => {
    event.preventDefault();
    dispatch({ type: "UI_LOADING", payload: true });
    authMiddleWare(history);
    const authToken = localStorage.getItem("AuthToken");
    let form_data = new FormData();
    form_data.append("image", image);
    //form_data.append("content", content);
    axios.defaults.headers.common = { Authorization: `${authToken}` };
    axios
      .post("/user/image", form_data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        if (error.response.status === 403) {
          history.push("/login");
        }
        console.log(error);
        dispatch({ type: "UI_LOADING", payload: false });
        setimageError("Error in posting the data");
      });
  };

  // const handleFileChange = (event) => {
  //   setfile(event.target.files[0]);
  // };

  // const fileUploadHandler = (event, description) => {
  //   event.preventDefault();
  //   dispatch({ type: "UI_LOADING", payload: true });
  //   authMiddleWare(history);
  //   const authToken = localStorage.getItem("AuthToken");
  //   let form_data = new FormData();
  //   form_data.append("file", file);
  //   //form_data.append("content", content);
  //   axios.defaults.headers.common = { Authorization: `${authToken}` };
  //   axios
  //     .post("/docs", form_data, {
  //       headers: {
  //         "content-type": "multipart/form-data",
  //         description: description,
  //       },
  //     })
  //     .then(() => {
  //       window.location.reload();
  //     })
  //     .catch((error) => {
  //       if (error.response.status === 403) {
  //         history.push("/login");
  //       }
  //       console.log(error);
  //       dispatch({ type: "UI_LOADING", payload: false });
  //       setimageError("Error in posting the data");
  //     });
  // };

  const updateFormValues = (event) => {
    event.preventDefault();
    setbuttonLoading(true);
    authMiddleWare(history);
    const authToken = localStorage.getItem("AuthToken");
    axios.defaults.headers.common = { Authorization: `${authToken}` };
    const formRequest = {
      firstName: firstName,
      lastName: lastName,
      phone: phone,
    };
    axios
      .post("/user", formRequest)
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        if (error.response.status === 403) {
          history.push("/login");
        }
        console.log(error);
        setbuttonLoading(false);
      });
  };

  // const handleClickOpen = () => {
  //   setalertOpen(true);
  // };

  const handleClose = () => {
    setalertOpen(false);
  };

  if (state.uiLoading === true) {
    return (
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {state.uiLoading && (
          <CircularProgress size={150} className={classes.uiProgess} />
        )}
      </main>
    );
  } else {
    return (
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="sm">
          <main className={classes.content}>
            <div className={classes.toolbar} />
            <div>
              <Dialog
                open={alertOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    Παρακαλούμε ολοκληρώστε την εγγραφή σας συμπληρώνοντας τα
                    στοιχεία σας.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    OK
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
            <Card {...rest} className={clsx(classes.root, classes)}>
              <CardContent>
                <div className={classes.details}>
                  <div>
                    <Typography
                      className={classes.locationText}
                      gutterBottom
                      variant="h4"
                    >
                      {firstName} {lastName}
                      {/* {state.userdata.firstName} {state.userdata.lastName} */}
                    </Typography>
                    <Button
                      variant="outlined"
                      color="primary"
                      type="submit"
                      size="small"
                      startIcon={<CloudUploadIcon />}
                      className={classes.uploadButton}
                      onClick={profilePictureHandler}
                    >
                      ΑΝΕΒΑΣΜΑ ΦΩΤΟΓΡΑΦΙΑΣ
                    </Button>
                    <input type="file" onChange={handleImageChange} />

                    {imageError ? (
                      <div className={classes.customError}>
                        {" "}
                        Wrong Image Format || Supported Format are PNG and JPG
                      </div>
                    ) : (
                      false
                    )}
                  </div>
                </div>
                <div className={classes.progress} />
              </CardContent>
            </Card>

            <br />
            <Card {...rest} className={clsx(classes.root, classes)}>
              <form autoComplete="off" noValidate>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Όνομα"
                        margin="dense"
                        name="firstName"
                        variant="outlined"
                        value={firstName}
                        onChange={(event) => setfirstName(event.target.value)}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Επώνυμο"
                        margin="dense"
                        name="lastName"
                        variant="outlined"
                        value={lastName}
                        onChange={(event) => setlastName(event.target.value)}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Email"
                        margin="dense"
                        name="email"
                        variant="outlined"
                        disabled={true}
                        value={email}
                        onChange={(event) => setemail(event.target.value)}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Τηλέφωνο"
                        margin="dense"
                        name="phone"
                        type="number"
                        variant="outlined"
                        value={phone}
                        onChange={(event) => setphone(event.target.value)}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    className={classes.submitButton}
                    onClick={updateFormValues}
                    disabled={
                      buttonLoading ||
                      !state.userdata.firstName ||
                      !state.userdata.lastName
                    }
                  >
                    ΑΠΟΘΗΚΕΥΣΗ
                    {buttonLoading && (
                      <CircularProgress size={30} className={classes.progess} />
                    )}
                  </Button>
                  <Box display="flex" justifyContent="flex-end">
                    <Tooltip title="Διαγραφή">
                      <IconButton
                        aria-label="delete"
                        onClick={() => setopenDelete(true)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </CardActions>
              </form>
            </Card>
          </main>
        </Container>
        <Dialog
          open={openDelete}
          onClose={() => setopenDelete(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Είστε σίγουροι ότι θέλετε να διαγράψετε τον λογαριασμό σας;
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Εάν επιλέξεται Ναι, δεν υπάρχει δυνατότητα επαναφοράς.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={deleteUser} color="primary" autoFocus>
              Ναι
            </Button>
            <Button onClick={() => setopenDelete(false)} color="primary">
              Όχι
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}
