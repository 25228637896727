import React, { useContext } from "react";
import { Context } from "../state";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  InputLabel: {
    color: "rgba(255, 255, 255, 0.7)",
  },
  Select: {
    color: "#4fc3f7",
  },
}));

export default function SimpleSelect() {
  const classes = useStyles();
  const [state, dispatch] = useContext(Context);
  const [currentProperty, setCurrentProperty] = React.useState(
    state.currentProperty
  );
  const [address, setAddress] = React.useState(state.properties[0].address);

  const handleChange = (event) => {
    if (event.target.value === -1) {
      dispatch({ type: "NEW_PROPERY_DIALOG", payload: true });
      setAddress("");
    } else {
      setCurrentProperty(event.target.value);
      dispatch({ type: "CURRENT_PROPERTY", payload: event.target.value });
      setAddress(state.properties[event.target.value].address);
    }
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel
        id="demo-simple-select-autowidth-label"
        margin="dense"
        className={classes.InputLabel}
      >
        Επιλογή ακινήτου
      </InputLabel>
      <Select
        className={classes.Select}
        labelId="demo-simple-select-autowidth-label"
        id="demo-simple-select-autowidth"
        value={currentProperty}
        onChange={handleChange}
        autoWidth
      >
        {state.properties[0]
          ? [...Array(state.properties.length).keys()].map((value) => (
              <MenuItem key={value} value={value}>
                {state.properties[value].name}
              </MenuItem>
            ))
          : null}
        <MenuItem value={-1}>
          <em>Προσθήκη νέου</em>
        </MenuItem>
      </Select>
      <em>
        <FormHelperText className={classes.InputLabel} margin="dense">
          {address ? state.properties[currentProperty].address : null}
        </FormHelperText>
      </em>
    </FormControl>
  );
}
