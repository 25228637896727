import React, { useContext, useEffect } from "react";
import { Context } from "../../state";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";

import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles((theme) => ({
  formroot: {
    "& > *": {
      margin: theme.spacing(2),
    },
    paddingBottom: 20,
  },
  gridroot: {
    flexGrow: 1,
  },
  title: {
    paddingBottom: 20,
  },
}));

export default function Account() {
  const [state, dispatch] = useContext(Context);
  const classes = useStyles();

  const [userdata, setuserdata] = React.useState(state.userdata);
  const [email] = React.useState(userdata.email);

  useEffect(() => {
    if (
      state.userdata.firstName &&
      state.userdata.lastName &&
      state.userdata.email &&
      state.userdata.phone
    ) {
      dispatch({ type: "CONTINUE", payload: true });
    }
    // eslint-disable-next-line
  }, []);

  const handleChange = (event) => {
    setuserdata({ ...userdata, [event.target.id]: event.target.value });

    let newuserdata = { ...userdata, [event.target.id]: event.target.value };
    dispatch({ type: "UPDATE_USERDATA", payload: newuserdata });

    // console.log("!!!!useContext USER DATA!!!!", state.userdata);
    // console.log("!!!!useState USER DATA!!!!", userdata);
    // console.log("!!!!!!!!!!!INITIAL USER DATA!!!!!!!!!", email);

    if (
      (userdata.firstName &&
        userdata.lastName &&
        userdata.email &&
        event.target.id === "phone" &&
        event.target.value.length !== 0) ||
      (userdata.phone &&
        userdata.lastName &&
        userdata.email &&
        event.target.id === "firstName" &&
        event.target.value.length !== 0) ||
      (userdata.phone &&
        userdata.firstName &&
        userdata.email &&
        event.target.id === "lastName" &&
        event.target.value.length !== 0) ||
      (userdata.phone &&
        userdata.lastName &&
        userdata.firstName &&
        event.target.id === "email" &&
        event.target.value.length !== 0)
    ) {
      dispatch({ type: "CONTINUE", payload: true });
    } else {
      dispatch({ type: "CONTINUE", payload: false });
    }
  };

  return (
    <form className={classes.formroot} noValidate autoComplete="off">
      <div className={classes.gridroot}>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          Καταχωρήστε κάποια βασικά στοιχεία
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth={true}>
              <InputLabel htmlFor="firstName">Όνομα</InputLabel>
              <Input
                id="firstName"
                value={userdata.firstName}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth={true}>
              <InputLabel htmlFor="lastName">Επώνυμο</InputLabel>
              <Input
                id="lastName"
                value={userdata.lastName}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth={true}>
              <InputLabel htmlFor="email">Email</InputLabel>
              <Input
                id="email"
                disabled={email ? true : false}
                value={userdata.email}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth={true}>
              <InputLabel htmlFor="phone">Τηλέφωνο</InputLabel>
              <Input
                id="phone"
                value={userdata.phone}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>
        </Grid>
      </div>
    </form>
  );
}
