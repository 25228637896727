import React, { useContext, useEffect } from "react";
import { Context } from "../state";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";

import axios from "axios";
import { authMiddleWare } from "../util/auth";

import { Container } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Card,
  CardActions,
  CardContent,
  Button,
  Grid,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  formroot: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  gridroot: {
    flexGrow: 1,
  },
  root: {
    padding: theme.spacing(2),
  },
}));

export default function Account() {
  const [state, dispatch] = useContext(Context);
  const classes = useStyles();
  let history = useHistory();

  const [buttonLoading, setbuttonLoading] = React.useState(false);
  const [openDelete, setopenDelete] = React.useState(false);
  const [properyData, setProperyData] = React.useState({
    name: state.properties[state.currentProperty].name,
    address: state.properties[state.currentProperty].address,
    number: state.properties[state.currentProperty].number,
    tk: state.properties[state.currentProperty].tk,
    city: state.properties[state.currentProperty].city,
    state: state.properties[state.currentProperty].state,
  });

  useEffect(() => {
    setProperyData({
      name: state.properties[state.currentProperty].name,
      address: state.properties[state.currentProperty].address,
      number: state.properties[state.currentProperty].number,
      tk: state.properties[state.currentProperty].tk,
      city: state.properties[state.currentProperty].city,
      state: state.properties[state.currentProperty].state,
    });
  }, [state.properties, state.currentProperty]);

  const handleChange = (event) => {
    setProperyData({ ...properyData, [event.target.id]: event.target.value });
    console.log("properyData", properyData);
  };

  const handleSubmit = (event) => {
    setbuttonLoading(true);
    authMiddleWare(history);
    event.preventDefault();
    const property = {
      name: properyData.name,
      address: properyData.address,
      number: properyData.number,
      tk: properyData.tk,
      city: properyData.city,
      state: properyData.state,
    };
    let options = {
      url: `/properties/${state.properties[state.currentProperty].propertyId}`,
      method: "put",
      data: property,
    };
    const authToken = localStorage.getItem("AuthToken");
    axios.defaults.headers.common = { Authorization: `${authToken}` };
    axios(options)
      .then(() => {
        let properties = state.properties;
        Object.keys(property).map(
          (key, index) =>
            (properties[state.currentProperty][key] = property[key])
        );
        dispatch({ type: "UPDATE_PROPERTIES", payload: properties });

        setbuttonLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteProperty = (event) => {
    authMiddleWare(history);
    const propertyId = state.properties[state.currentProperty].propertyId;
    dispatch({ type: "UI_LOADING", payload: true });

    const authToken = localStorage.getItem("AuthToken");
    axios.defaults.headers.common = { Authorization: `${authToken}` };
    axios
      .delete(`properties/${propertyId}`)
      .then(() => {
        let newProperties = state.properties;
        newProperties.splice(state.currentProperty, 1);
        dispatch({ type: "UPDATE_PROPERTIES", payload: newProperties });
        dispatch({ type: "CURRENT_PROPERTY", payload: 0 });

        setopenDelete(false);
        dispatch({ type: "UI_LOADING", payload: false });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      <Container maxWidth="sm">
        <Card className={classes.root}>
          <CardContent>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
            >
              Επεξεργασία χαρακτηριστικών ακινήτου
            </Typography>
          </CardContent>
          <form className={classes.formroot} noValidate autoComplete="off">
            <div className={classes.gridroot}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControl fullWidth={true}>
                    <InputLabel htmlFor="name">Φιλική ονομασία</InputLabel>
                    <Input
                      placeholder="π.χ: Σπίτι"
                      id="name"
                      value={properyData.name}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth={true}>
                    <InputLabel htmlFor="address">Διεύθυνση</InputLabel>
                    <Input
                      id="address"
                      value={properyData.address}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth={true}>
                    <InputLabel htmlFor="number">Αριθμός</InputLabel>
                    <Input
                      id="number"
                      value={properyData.number}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>
                <Grid item md xs={6}>
                  <FormControl fullWidth={true}>
                    <InputLabel htmlFor="tk">ΤΚ</InputLabel>
                    <Input
                      id="tk"
                      value={properyData.tk}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>
                <Grid item md xs={6}>
                  <FormControl fullWidth={true}>
                    <InputLabel htmlFor="city">Πόλη</InputLabel>
                    <Input
                      id="city"
                      value={properyData.city}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>
                <Grid item md xs={6}>
                  <FormControl fullWidth={true}>
                    <InputLabel htmlFor="state">Νομός</InputLabel>
                    <Input
                      id="state"
                      value={properyData.state}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </div>
          </form>
          <CardActions>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              onClick={handleSubmit}
              disabled={buttonLoading}
            >
              ΑΠΟΘΗΚΕΥΣΗ
              {buttonLoading && <CircularProgress size={30} />}
            </Button>
            {state.properties.length > 1 && (
              <Box display="flex" justifyContent="flex-end">
                <Tooltip title="Διαγραφή">
                  <IconButton
                    aria-label="delete"
                    onClick={() => setopenDelete(true)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </CardActions>
        </Card>
      </Container>
      <Dialog
        open={openDelete}
        onClose={() => setopenDelete(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Είστε σίγουροι ότι θέλετε να διαγράψετε αυτό το ακίνητο;
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Εάν επιλέξεται Ναι, δεν υπάρχει δυνατότητα επαναφοράς.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteProperty} color="primary" autoFocus>
            Ναι
          </Button>
          <Button onClick={() => setopenDelete(false)} color="primary">
            Όχι
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
