import React, { useContext } from "react";
import { Context } from "../state";
import { authMiddleWare } from "../util/auth";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core/styles";

import axios from "axios";

import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  formroot: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  gridroot: {
    flexGrow: 1,
  },
}));

export default function ComposedTextField() {
  const [state, dispatch] = useContext(Context);
  const [buttonDisabled, setbuttonDisabled] = React.useState(true);
  const [properyData, setProperyData] = React.useState({
    name: "",
    address: "",
    number: "",
    tk: "",
    city: "",
    state: "",
  });
  const classes = useStyles();
  let history = useHistory();

  const handleChange = (event) => {
    setProperyData({ ...properyData, [event.target.id]: event.target.value });

    if (
      (properyData.name &&
        properyData.address &&
        properyData.number &&
        properyData.tk &&
        properyData.city &&
        event.target.id === "state" &&
        event.target.value.length !== 0) ||
      (properyData.name &&
        properyData.number &&
        properyData.tk &&
        properyData.city &&
        properyData.state &&
        event.target.id === "address" &&
        event.target.value.length !== 0) ||
      (properyData.name &&
        properyData.address &&
        properyData.tk &&
        properyData.city &&
        properyData.state &&
        event.target.id === "number" &&
        event.target.value.length !== 0) ||
      (properyData.name &&
        properyData.address &&
        properyData.number &&
        properyData.city &&
        properyData.state &&
        event.target.id === "tk" &&
        event.target.value.length !== 0) ||
      (properyData.name &&
        properyData.address &&
        properyData.number &&
        properyData.tk &&
        properyData.state &&
        event.target.id === "city" &&
        event.target.value.length !== 0) ||
      (properyData.address &&
        properyData.number &&
        properyData.tk &&
        properyData.city &&
        properyData.state &&
        event.target.id === "name" &&
        event.target.value.length !== 0)
    ) {
      setbuttonDisabled(false);
    } else {
      setbuttonDisabled(true);
    }
  };

  const submitProperty = (event) => {
    event.preventDefault();
    //this.setState({uiLoading: true,});
    authMiddleWare(history);
    dispatch({ type: "UI_LOADING", payload: true });

    let options = {
      url: `/properties/`,
      method: "post",
      data: properyData,
    };
    const authToken = localStorage.getItem("AuthToken");
    axios.defaults.headers.common = { Authorization: `${authToken}` };
    axios(options)
      .then((data) => {
        console.log("CREATE NEW PROPERTY RETURN DATA!!!", data.data);
        let properties = state.properties;
        properties[properties.length] = data.data;

        dispatch({ type: "CURRENT_PROPERTY", payload: properties.length - 1 });
        dispatch({ type: "UPDATE_PROPERTIES", payload: properties });
        dispatch({ type: "NEW_PROPERY_DIALOG", payload: false });
        dispatch({ type: "UI_LOADING", payload: false });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Dialog
      open={state.newProperyDialog}
      onClose={null}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Νέο ακίνητο</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Καταχωρήστε τις απαραίτητες πληροφορίες του ακινήτου.
        </DialogContentText>
        <form className={classes.formroot} noValidate autoComplete="off">
          <div className={classes.gridroot}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControl fullWidth={true}>
                  <InputLabel htmlFor="name">Φιλική ονομασία</InputLabel>
                  <Input
                    placeholder="π.χ: Σπίτι"
                    id="name"
                    value={properyData.name}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth={true}>
                  <InputLabel htmlFor="address">Διεύθυνση</InputLabel>
                  <Input
                    id="address"
                    value={properyData.address}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth={true}>
                  <InputLabel htmlFor="number">Αριθμός</InputLabel>
                  <Input
                    id="number"
                    value={properyData.number}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item md xs={6}>
                <FormControl fullWidth={true}>
                  <InputLabel htmlFor="tk">ΤΚ</InputLabel>
                  <Input
                    id="tk"
                    value={properyData.tk}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item md xs={6}>
                <FormControl fullWidth={true}>
                  <InputLabel htmlFor="city">Πόλη</InputLabel>
                  <Input
                    id="city"
                    value={properyData.city}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item md xs={6}>
                <FormControl fullWidth={true}>
                  <InputLabel htmlFor="state">Νομός</InputLabel>
                  <Input
                    id="state"
                    value={properyData.state}
                    onChange={handleChange}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </div>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() =>
            dispatch({ type: "NEW_PROPERY_DIALOG", payload: false })
          }
          color="primary"
        >
          Ακύρωση
        </Button>
        <Button
          disabled={buttonDisabled}
          onClick={submitProperty}
          color="primary"
        >
          Καταχώρηση
        </Button>
      </DialogActions>
    </Dialog>
  );
}
