import React, { Component } from "react";
import "./login.css";
import firebase from "firebase/app";
import "firebase/auth";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import axios from "axios";

import CircularProgress from "@material-ui/core/CircularProgress";

firebase.initializeApp({
  apiKey: "AIzaSyDnD72YuEGAp2CaRqhJOPt4iIncOQ_SZ0o",
  authDomain: "i-done-71476.firebaseapp.com",
});

class prebuiltuiApp extends Component {
  state = {
    isSignedIn: false,
    uiLoading: false,
  };
  uiConfig = {
    signInFlow: "popup",
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      firebase.auth.TwitterAuthProvider.PROVIDER_ID,
      firebase.auth.GithubAuthProvider.PROVIDER_ID,
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        requireDisplayName: false,
      },
      {
        provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
        defaultCountry: "GR",
      },
    ],
    callbacks: {
      signInSuccessWithAuthResult: () => false,
    },
  };

  UNSAFE_componentWillMount = () => {
    console.log("login componentWillMount");
    if (!localStorage.getItem("AuthToken")) {
      firebase.auth().signOut();
    }
    firebase.auth().onAuthStateChanged((user) => {
      this.setState({ isSignedIn: !!user });
      if (user != null) {
        user.getIdToken().then((accessToken) => {
          localStorage.setItem("AuthToken", `Bearer ${accessToken}`);
          localStorage.setItem("uid", user.uid);
          console.log("user uid", user.uid);

          //let authToken = localStorage.getItem("AuthToken");
          axios.defaults.headers.common = {
            Authorization: `Bearer ${accessToken}`,
          };
          axios
            .post("/signup")
            .then(() => {
              this.props.history.push("/");
            })
            .catch((error) => {
              if (error.response.status === 205) {
                this.props.history.push("/login");
              } else {
                console.log(error);
              }
            });
        });
      }
    });
  };

  render() {
    if (this.state.isSignedIn) {
      return (
        <div>
          {this.state.uiLoading && (
            <CircularProgress
              size={150}
              styles={{
                position: "fixed",
                zIndex: "1000",
                height: "31px",
                width: "31px",
                left: "calc(50% - 75px)",
                top: "calc(50% - 75px)",
              }}
            />
          )}
        </div>
        /*         <div className="App">
          <span>
            <div>Signed In!</div>
            <button onClick={() => firebase.auth().signOut()}>Sign out!</button>
            <h1>Welcome {firebase.auth().currentUser.displayName}</h1>
            <img
              alt="profile picture"
              src={firebase.auth().currentUser.photoURL}
            />
          </span>
        </div> */
        //<button onClick={() => firebase.auth().signOut()}>Sign out!</button>
        //<div>{this.props.history.push("/")}</div>
      );
    } else {
      return (
        <div className="App">
          <StyledFirebaseAuth
            uiConfig={this.uiConfig}
            firebaseAuth={firebase.auth()}
          />
        </div>
      );
    }
  }
}

export default prebuiltuiApp;
