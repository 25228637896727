import React, { useContext, useEffect } from "react";
import { Context } from "../state";
import { useHistory } from "react-router";

import axios from "axios";
import { authMiddleWare } from "../util/auth";

import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Card, Container } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import FirstUser from "./newUser/firstUser";
import FirstAccountType from "./newUser/firstAccountType";
import FirstProperty from "./newUser/firstProperty";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  cardroot: {
    padding: theme.spacing(1),
    paddingBottom: 20,
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  progess: {
    position: "absolute",
  },
}));

function getSteps() {
  return ["Στοιχεία χρήστη", "Επιλογή ιδιότητας", "Στοιχεία ακινήτου"];
}

function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return <FirstUser />;
    case 1:
      return <FirstAccountType />;
    case 2:
      return <FirstProperty />;
    default:
      return "Unknown stepIndex";
  }
}

export default function HorizontalLabelPositionBelowStepper() {
  const [state, dispatch] = useContext(Context);
  let history = useHistory();
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [buttonLoading, setbuttonLoading] = React.useState(false);
  const steps = getSteps();

  useEffect(() => {
    if (
      state.userdata.firstName &&
      state.userdata.lastName &&
      state.userdata.email &&
      state.userdata.phone
    ) {
      setActiveStep(1);
      dispatch({ type: "CONTINUE", payload: true });
    }
    // eslint-disable-next-line
  }, []);

  const updateAccount = () => {
    setbuttonLoading(true);
    authMiddleWare(history);
    const authToken = localStorage.getItem("AuthToken");
    axios.defaults.headers.common = { Authorization: `${authToken}` };
    const formRequest = {
      firstName: state.userdata.firstName,
      lastName: state.userdata.lastName,
      email: state.userdata.email,
      phone: state.userdata.phone,
      accountType: state.userdata.accountType,
    };
    axios
      .post("/user", formRequest)
      .then(() => {
        setbuttonLoading(false);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      })
      .catch((error) => {
        if (error.response.status === 403) {
          history.push("/login");
        }
        console.log(error);
        setbuttonLoading(false);
      });
  };

  const firstProperty = () => {
    setbuttonLoading(true);
    authMiddleWare(history);
    let options = {
      url: `/properties/`,
      method: "post",
      data: state.properties[0],
    };
    const authToken = localStorage.getItem("AuthToken");
    axios.defaults.headers.common = { Authorization: `${authToken}` };
    axios(options)
      .then(() => {
        axios.defaults.headers.common = { Authorization: `${authToken}` };
        const isNew = {
          isNew: false,
        };
        axios
          .post("/user", isNew)
          .then(() => {
            //setActiveStep((prevActiveStep) => prevActiveStep + 1);
            window.location.reload();
            //setbuttonLoading(false);
          })
          .catch((error) => {
            if (error.response.status === 403) {
              history.push("/login");
            }
            console.log(error);
            setbuttonLoading(false);
          });
      })
      .catch((error) => {
        if (error.response.status === 403) {
          history.push("/login");
        }
        console.log(error);
        setbuttonLoading(false);
      });
  };

  const handleNext = () => {
    //event.preventDefault();
    if (activeStep === 0) {
      updateAccount();
      dispatch({ type: "CONTINUE", payload: true });
    }
    if (activeStep === 1) {
      updateAccount();
      dispatch({ type: "CONTINUE", payload: false });
    }
    if (activeStep === 2) {
      firstProperty();
    }
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <React.Fragment>
      <Container maxWidth="sm">
        <Card className={classes.cardroot}>
          <div className={classes.root}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <div>
              {activeStep === steps.length ? (
                <div>
                  <Typography className={classes.instructions}>
                    All steps completed
                  </Typography>
                  <Button onClick={handleReset}>Reset</Button>
                </div>
              ) : (
                <div>
                  {getStepContent(activeStep)}
                  <div>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.backButton}
                    >
                      Πίσω
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={buttonLoading || !state.continue}
                      onClick={handleNext}
                    >
                      {activeStep === steps.length - 1 ? "Τέλος" : "Επόμενο"}
                      {buttonLoading && (
                        <CircularProgress
                          size={30}
                          className={classes.progess}
                        />
                      )}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Card>
      </Container>
    </React.Fragment>
  );
}
