import React, { useCallback, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import axios from "axios";

import {
  createMuiTheme,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Hidden from "@material-ui/core/Hidden";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Navigator from "./Navigator";
import Header from "./Header";
import CircularProgress from "@material-ui/core/CircularProgress";

import CreateProperty from "../components/createProperty";
import NewUser from "../components/newUser";

import { authMiddleWare } from "../util/auth";

import { Context } from "../state";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://walldone.gr/">
        walldone
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

let theme = createMuiTheme({
  palette: {
    primary: {
      light: "#63ccff",
      main: "#009be5",
      dark: "#006db3",
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: "#18202c",
      },
    },
    MuiButton: {
      label: {
        textTransform: "none",
      },
      contained: {
        boxShadow: "none",
        "&:active": {
          boxShadow: "none",
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: "none",
        margin: "0 16px",
        minWidth: 0,
        padding: 0,
        [theme.breakpoints.up("md")]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: "#404854",
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: "inherit",
        marginRight: 0,
        "& svg": {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
};

const drawerWidth = 256;

const styles = {
  root: {
    display: "flex",
    minHeight: "100vh",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  app: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  main: {
    flex: 1,
    padding: theme.spacing(6, 4),
    background: "#eaeff1",
  },
  footer: {
    padding: theme.spacing(2),
    background: "#eaeff1",
  },
  uiProgess: {
    position: "fixed",
    zIndex: "1000",
    height: "31px",
    width: "31px",
    left: "calc(50% - 75px)",
    top: "calc(50% - 75px)",
  },
};

function Paperbase(props) {
  const { classes } = props;
  let history = useHistory();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [state, dispatch] = useContext(Context);

  const handleGetUserDetails = useCallback(
    (response) => {
      let userdata = {};
      //handle data
      userdata.firstName = response.data.userCredentials.firstName;
      userdata.lastName = response.data.userCredentials.lastName;
      userdata.email = response.data.userCredentials.email;
      userdata.phone = response.data.userCredentials.phone;
      userdata.sign_in_provider =
        response.data.userCredentials.sign_in_provider;
      userdata.uid = response.data.userCredentials.uid;
      userdata.name = response.data.userCredentials.name;
      userdata.address = response.data.userCredentials.address;
      userdata.isNew = response.data.userCredentials.isNew;

      //avatar pic
      userdata.profilePicture = response.data.userCredentials.profilePicture;
      if (!response.data.userCredentials.profilePicture) {
        if (response.data.userCredentials.picture) {
          userdata.profilePicture = response.data.userCredentials.picture;
        }
      }
      if (response.data.userCredentials.imageUrl) {
        userdata.profilePicture = response.data.userCredentials.imageUrl;
      }
      //account type
      if (!response.data.userCredentials.accountType) {
        userdata.accountType = "idiotis";
      } else {
        userdata.accountType = response.data.userCredentials.accountType;
      }
      //handle names
      if (!userdata.lastName && userdata.name) {
        userdata.firstName = userdata.name.split(" ").slice(0, -1).join(" ");
        userdata.lastName = userdata.name.split(" ").slice(-1).join(" ");
      }
      //phone
      if (response.data.userCredentials.phone_number) {
        userdata.phone = response.data.userCredentials.phone_number;
      }

      dispatch({ type: "UPDATE_USERDATA", payload: userdata });
      console.log("handleGetUserDetails userdata", userdata);
      return userdata;
    },
    [dispatch]
  );

  const fetchUser = useCallback(() => {
    console.log("DataFetching...");
    dispatch({ type: "UI_LOADING", payload: true });
    const authToken = localStorage.getItem("AuthToken");
    axios.defaults.headers.common = { Authorization: `${authToken}` };
    axios
      .get("/user")
      .then((response) => {
        console.log("got user data", response.data);
        //dispatch({ type: "UI_LOADING", payload: false });
        handleGetUserDetails(response);
      })
      .catch((error) => {
        console.log("DataFetching got error", error);
        if (error.response.status === 403) {
          history.push("/login");
        }
      });

    axios.defaults.headers.common = { Authorization: `${authToken}` };
    axios
      .get("/properties")
      .then((response) => {
        console.log("got user properties", response.data);
        dispatch({ type: "UPDATE_PROPERTIES", payload: response.data });

        if (localStorage.getItem("CURRENT_PROPERTY")) {
          dispatch({
            type: "CURRENT_PROPERTY",
            payload: localStorage.getItem("CURRENT_PROPERTY"),
          });
        }
        dispatch({ type: "UI_LOADING", payload: false });
      })
      .catch((error) => {
        console.log("DataFetching got error", error);
        if (error.response.status === 404) {
          console.log("!!!!!!!!!!!No properties found");
          dispatch({ type: "UPDATE_PROPERTIES", payload: [{}] });
          dispatch({ type: "UI_LOADING", payload: false });
        }
      });
  }, [dispatch, handleGetUserDetails, history]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    if (state.userdata.isNew) {
      dispatch({ type: "UPDATE_CONTENT", payload: <NewUser /> });
    }
    // eslint-disable-next-line
  }, [state.userdata.isNew]);

  useEffect(() => {
    authMiddleWare(history);
    fetchUser();
  }, [history, fetchUser]);

  if (state.uiLoading === true) {
    return (
      <div className={classes.root}>
        {state.uiLoading && (
          <CircularProgress size={150} className={classes.uiProgess} />
        )}
      </div>
    );
  } else {
    return (
      <React.Fragment>
        {/* <DataFetching /> */}
        <ThemeProvider theme={theme}>
          <div className={classes.root}>
            <CssBaseline />
            {!state.userdata.isNew && (
              <nav className={classes.drawer}>
                <Hidden smUp implementation="js">
                  <Navigator
                    PaperProps={{ style: { width: drawerWidth } }}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                  />
                </Hidden>
                <Hidden xsDown implementation="css">
                  <Navigator PaperProps={{ style: { width: drawerWidth } }} />
                </Hidden>
              </nav>
            )}

            <div className={classes.app}>
              <Header onDrawerToggle={handleDrawerToggle} />
              <main className={classes.main}>{state.content}</main>
              <footer className={classes.footer}>
                <Copyright />
              </footer>
            </div>
          </div>
        </ThemeProvider>
        <CreateProperty />
      </React.Fragment>
    );
  }
}

Paperbase.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default React.memo(withStyles(styles)(Paperbase));
