import React, { useContext } from "react";
import { Context } from "../state";
import PropTypes from "prop-types";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";

import SelectProperty from "../components/selectProperty";
import EditProperty from "../components/editProperty";
import Files from "../components/files";
import Documents from "../components/documents";
import Account from "../components/account";

import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PeopleIcon from "@material-ui/icons/People";
import DnsRoundedIcon from "@material-ui/icons/DnsRounded";
import PermMediaOutlinedIcon from "@material-ui/icons/PhotoSizeSelectActual";
import PublicIcon from "@material-ui/icons/Public";
import SettingsEthernetIcon from "@material-ui/icons/SettingsEthernet";
import SettingsInputComponentIcon from "@material-ui/icons/SettingsInputComponent";
import TimerIcon from "@material-ui/icons/Timer";
import SettingsIcon from "@material-ui/icons/Settings";
import PhonelinkSetupIcon from "@material-ui/icons/PhonelinkSetup";

const categories = [
  {
    id: "Διαχείριση ακινήτου",
    children: [
      {
        id: "Πληροφορίες",
        icon: <PeopleIcon />,
        component: <EditProperty />,
        disabled: false,
      },
      {
        id: "Έγγραφα",
        icon: <DnsRoundedIcon />,
        component: (
          <Documents
            docTypes={[
              { simvolaio: "Συμβόλαιο" },
              { logariasmos: "Λογαριαμός" },
              { PEA: "ΠΕΑ" },
              { other: "Άλλο" },
            ]}
            url="docs"
          />
        ),
        disabled: false,
      },
      {
        id: "Ιδιοκτήτες",
        icon: <PermMediaOutlinedIcon />,
        component: <Files />,
        disabled: true,
      },
      {
        id: "Ηλεκτρονική Ταυτότητα",
        icon: <PublicIcon />,
        component: <Files />,
        disabled: true,
      },
      {
        id: "Εξοικονομώ - Αυτονομώ",
        icon: <SettingsEthernetIcon />,
        component: <Files />,
        disabled: true,
      },
      {
        id: "Ανακαίνιση",
        icon: <SettingsInputComponentIcon />,
        component: <Files />,
        disabled: true,
      },
    ],
  },
  {
    id: "Προφίλ",
    children: [
      {
        id: "Προσωπικές πληροφορίες",
        icon: <SettingsIcon />,
        component: <Account />,
        disabled: false,
      },
      {
        id: "Προσωπικά έγγραφα",
        icon: <TimerIcon />,
        component: (
          <Documents
            docTypes={[
              { ADT: "Δελτίο Ταυτότητας" },
              { E1: "Ε1" },
              { E2: "Ε2" },
            ]}
            url="userDocs"
          />
        ),
        disabled: false,
      },
      {
        id: "Αποσύνδεση",
        icon: <PhonelinkSetupIcon />,
      },
    ],
  },
];

const styles = (theme) => ({
  categoryHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  item: {
    paddingTop: 1,
    paddingBottom: 1,
    color: "rgba(255, 255, 255, 0.7)",
    "&:hover,&:focus": {
      backgroundColor: "rgba(255, 255, 255, 0.08)",
    },
  },
  itemCategory: {
    backgroundColor: "#232f3e",
    boxShadow: "0 -1px 0 #404854 inset",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  firebase: {
    fontSize: 24,
    color: theme.palette.common.white,
  },
  itemActiveItem: {
    color: "#4fc3f7",
  },
  itemPrimary: {
    fontSize: "inherit",
  },
  itemIcon: {
    minWidth: "auto",
    marginRight: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
});

function Navigator(props) {
  const { classes, ...other } = props;
  //const dispatch = useContext(Context)[1];
  const [state, dispatch] = useContext(Context);
  let history = useHistory();

  // useEffect(() => {
  //   dispatch({ type: "UPDATE_CONTENT", payload: "Files" });
  // }, [dispatch]);

  const logoutHandler = (event) => {
    localStorage.clear();
    history.push("/login");
  };

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem
          className={clsx(classes.firebase, classes.item, classes.itemCategory)}
        >
          i-done - beta
        </ListItem>{" "}
        <ListItem className={clsx(classes.item, classes.itemCategory)}>
          <SelectProperty />
        </ListItem>
        {categories.map(({ id, children }) => (
          <React.Fragment key={id}>
            <ListItem className={classes.categoryHeader}>
              <ListItemText
                classes={{
                  primary: classes.categoryHeaderPrimary,
                }}
              >
                {id}
              </ListItemText>
            </ListItem>
            {children.map(({ id: childId, icon, component, disabled }) => (
              <ListItem
                key={childId}
                button
                disabled={disabled}
                onClick={() => {
                  if (childId !== "Αποσύνδεση") {
                    dispatch({ type: "UPDATE_CONTENT", payload: component });
                    dispatch({ type: "CURRENT_CONTENT", payload: childId });
                  } else {
                    logoutHandler();
                  }
                }}
                className={clsx(
                  classes.item,
                  childId === state.currentContent && classes.itemActiveItem
                )}
              >
                <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
                <ListItemText
                  classes={{
                    primary: classes.itemPrimary,
                  }}
                >
                  {childId}
                </ListItemText>
              </ListItem>
            ))}

            <Divider className={classes.divider} />
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  );
}

Navigator.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Navigator);
