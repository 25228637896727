import React, { createContext, useReducer } from "react";
import Content from "./pages/startPage";

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_USERDATA":
      return {
        ...state,
        userdata: action.payload,
      };
    case "UPDATE_PROPERTIES":
      return {
        ...state,
        properties: action.payload,
      };
    case "UPDATE_CONTENT":
      return {
        ...state,
        content: action.payload,
      };
    case "CURRENT_CONTENT":
      return {
        ...state,
        currentContent: action.payload,
      };
    case "UI_LOADING":
      return {
        ...state,
        uiLoading: action.payload,
      };
    case "CONTINUE":
      return {
        ...state,
        continue: action.payload,
      };
    case "NEW_PROPERY_DIALOG":
      return {
        ...state,
        newProperyDialog: action.payload,
      };
    case "CURRENT_PROPERTY":
      localStorage.setItem("CURRENT_PROPERTY", action.payload);
      return {
        ...state,
        currentProperty: action.payload,
      };
    default:
      return state;
  }
};

const initialState = {
  userdata: {},
  properties: ["Loading"],
  currentProperty: 0,
  content: <Content />,
  currentContent: "Αρχική",
  uiLoading: false,
  continue: false,
  newProperyDialog: false,
};

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  );
};

export const Context = createContext(initialState);

export default Store;
