import React, { useContext } from "react";
import { Context } from "../../state";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";

import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles((theme) => ({
  formroot: {
    "& > *": {
      margin: theme.spacing(2),
    },
    paddingBottom: 20,
  },
  gridroot: {
    flexGrow: 1,
  },
  title: {
    paddingBottom: 0,
  },
}));

export default function Account() {
  const dispatch = useContext(Context)[1];
  const classes = useStyles();

  const [properyData, setProperyData] = React.useState({});

  const handleChange = (event) => {
    setProperyData({ ...properyData, [event.target.id]: event.target.value });
    let properties = [];
    properties[0] = { ...properyData, [event.target.id]: event.target.value };
    dispatch({ type: "UPDATE_PROPERTIES", payload: properties });

    if (
      (properyData.name &&
        properyData.address &&
        properyData.number &&
        properyData.tk &&
        properyData.city &&
        event.target.id === "state" &&
        event.target.value.length !== 0) ||
      (properyData.name &&
        properyData.number &&
        properyData.tk &&
        properyData.city &&
        properyData.state &&
        event.target.id === "address" &&
        event.target.value.length !== 0) ||
      (properyData.name &&
        properyData.address &&
        properyData.tk &&
        properyData.city &&
        properyData.state &&
        event.target.id === "number" &&
        event.target.value.length !== 0) ||
      (properyData.name &&
        properyData.address &&
        properyData.number &&
        properyData.city &&
        properyData.state &&
        event.target.id === "tk" &&
        event.target.value.length !== 0) ||
      (properyData.name &&
        properyData.address &&
        properyData.number &&
        properyData.tk &&
        properyData.state &&
        event.target.id === "city" &&
        event.target.value.length !== 0) ||
      (properyData.address &&
        properyData.number &&
        properyData.tk &&
        properyData.city &&
        properyData.state &&
        event.target.id === "name" &&
        event.target.value.length !== 0)
    ) {
      dispatch({ type: "CONTINUE", payload: true });
    } else {
      dispatch({ type: "CONTINUE", payload: false });
    }
  };

  return (
    <form className={classes.formroot} noValidate autoComplete="off">
      <Typography className={classes.title} color="textSecondary" gutterBottom>
        Καταχωρήστε το πρώτο σας ακίνητο
      </Typography>
      <div className={classes.gridroot}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl fullWidth={true}>
              <InputLabel htmlFor="name">Φιλική ονομασία</InputLabel>
              <Input
                placeholder="π.χ: Σπίτι"
                id="name"
                value={properyData.name}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth={true}>
              <InputLabel htmlFor="address">Διεύθυνση</InputLabel>
              <Input
                id="address"
                value={properyData.address}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth={true}>
              <InputLabel htmlFor="number">Αριθμός</InputLabel>
              <Input
                id="number"
                value={properyData.number}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>
          <Grid item md xs={6}>
            <FormControl fullWidth={true}>
              <InputLabel htmlFor="tk">ΤΚ</InputLabel>
              <Input id="tk" value={properyData.tk} onChange={handleChange} />
            </FormControl>
          </Grid>
          <Grid item md xs={6}>
            <FormControl fullWidth={true}>
              <InputLabel htmlFor="city">Πόλη</InputLabel>
              <Input
                id="city"
                value={properyData.city}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>
          <Grid item md xs={6}>
            <FormControl fullWidth={true}>
              <InputLabel htmlFor="state">Νομός</InputLabel>
              <Input
                id="state"
                value={properyData.state}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>
        </Grid>
      </div>
    </form>
  );
}
