import React, { useContext } from "react";
import { Context } from "../../state";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  formroot: {
    "& > *": {
      margin: theme.spacing(2),
    },
    paddingBottom: 20,
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  title: {
    paddingBottom: 0,
  },
}));

export default function Account() {
  const [state, dispatch] = useContext(Context);
  const classes = useStyles();
  const [accountType, setAccountType] = React.useState(
    state.userdata.accountType
  );

  const handleChange = (event) => {
    setAccountType(event.target.value);
    let newuserdata = state.userdata;
    newuserdata["accountType"] = event.target.value;
    dispatch({ type: "UPDATE_USERDATA", payload: newuserdata });
  };

  return (
    <form className={classes.formroot} noValidate autoComplete="off">
      <Typography className={classes.title} color="textSecondary" gutterBottom>
        Επιλέξτε τον τύπο λογαριασμού με τον οποίο θέλετε να χρησιμοποιήσετε την
        πλατφόρμα
      </Typography>
      {/* <Box display="flex" justifyContent="center"> */}
      <FormControl variant="outlined" className={classes.formControl}>
        <Select value={accountType} onChange={handleChange}>
          <MenuItem value="idiotis">Ιδιώτης</MenuItem>
          <MenuItem disabled value="diaxiristis">
            Διαχειριστής
          </MenuItem>
          <MenuItem disabled value="sinergatis">
            Συνεργάτης
          </MenuItem>
        </Select>
      </FormControl>
      {/* </Box> */}
    </form>
  );
}
